<template>
  <div class="QingXi" v-loading="loading">
    <!-- 夏季：6-8月 -->
    <div class="summer" v-if="nowMoth >= 6 && nowMoth <= 8">
      <div class="banner">
        <!-- <img width="100%" src="../../../assets/img/QingXi/summer_banner.jpg" alt="" srcset=""> -->
        <el-carousel :height="imgHeight">
          <el-carousel-item v-for="(item, index) in summerImgList" :key="'summer'+index+nowMoth">
            <img
              width="100%"
              v-lazy="item.imgUrl"
              ref="imgH"
              @load="imgLoad"
              alt=""
              srcset=""
            />
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- <div class="con1">
        <img src="../../../assets/img/QingXi/summer_jianjie.png" alt="">
          <div class="desc">            
              清溪甜樱桃于上世纪80、90年代开始种植，主要种植品种有红灯、拉宾斯、美早、冰库等，由于独特的气候条件，清溪镇甜樱桃果大、色泽鲜艳、含糖量高、裂果少、病虫害轻，且成熟早，市场竞争力强。以红灯为例，最早可在“五一黄金周”采收，5月30日——6月15日开始大量上市，紫红色，平均单果重15G。销售到较远地方时，可在果色鲜红而未转紫色时采收，此时果子硬度较大，便于长途运输。同时，由于海拔高、垂直气候明显以及小气候差异大，同一品种在不同海拔高度成熟期相差很大，可延长供应期，仅红灯品种的成熟期最早和最晚可相差30天。            
          </div>        
      </div> -->
      <!-- 事项公开标题 -->
      <div style="margin-top: 30px;text-align:center;">
          <img src="../../../assets/img/red.png" alt="">
          <div class="bottom_br"></div>
        </div>
      <!-- 事项公开搜索 -->
      <div class="search-box">
        <el-input
          placeholder="搜索服务事项"
          suffix-icon="el-icon-search"
          v-model="keywords" @focus="toSearchView"
        >
        </el-input>
      </div>
      <GovernmentServices :mapId="mapId" :name="name" color="#920C05"></GovernmentServices>
    </div>
    <!-- 秋季：9-11月 -->
    <div class="autumn" v-else-if="nowMoth >= 9 && nowMoth <= 11">
      <div class="banner">
        <!-- <img width="100%" src="../../../assets/img/QingXi/autumn_banner.jpg" alt="" srcset=""> -->
        <el-carousel :height="imgHeight">
          <el-carousel-item v-for="(item, index) in autumnImgList" :key="'autumn'+index+nowMoth">
            <img
              width="100%"
              v-lazy="item.imgUrl"
              ref="imgH"
              @load="imgLoad"
              alt=""
              srcset=""
            />
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- <div class="con1">
        <img src="../../../assets/img/QingXi/autumn_jianjie.png" alt="">
          <div class="desc">
            <p>
              清溪花椒历史悠久，声名远播，蜚声中外。自唐代元和年间(约公元805-820年)就被列为贡品，历朝历代都作为贡品进贡皇室，故又名“贡椒"。以其色泽丹红，粒大油重，芳香浓郁、醇麻爽口等独一无二的品质而享誉全国。以得天独厚的地理气候优势，清溪花椒挥发性芳香油含量8%-12%，其它花椒为3%-7%。芳樟醇含量在53.34%，远高于其他种类花椒。
            </p>
            <p>
              现种植面积1133HM2，主要种植品种有正路椒、大红袍等，建成以顺利村为主核心的“千亩贡椒示范基地”，按照“合作社+基地+农户”的生产模式，推行肥水一体化、机械化作业等技术，实现统一采摘统一销售。清溪贡椒种植密度在120-150株/亩，产量可达到250-300KG/亩，收入可达到6000-10000元/亩。
            </p>
          </div>        
      </div> -->
      <!-- 事项公开标题 -->
      <div style="margin-top: 30px;text-align:center;">
          <img src="../../../assets/img/dark blue.png" alt="">
          <div class="bottom_br"></div>
        </div>
      <!-- 事项公开搜索 -->
      <div class="search-box">
        <el-input
          placeholder="搜索服务事项"
          suffix-icon="el-icon-search"
          v-model="keywords" @focus="toSearchView"
        >
        </el-input>
      </div>
      <GovernmentServices :mapId="mapId" :name="name" color="#384890"></GovernmentServices>
    </div>
    <!-- 四季 -->
    <div class="winter" v-else>
      <div class="banner">
        <!-- <img width="100%" src="../../../assets/img/QingXi/banner.jpg" alt="" srcset=""> -->
        <el-carousel :height="imgHeight">
          <el-carousel-item v-for="(item, index) in winterImgList" :key="'winter'+index+nowMoth">
            <img
              width="100%"
              v-lazy="item.imgUrl"
              ref="imgH"
              @load="imgLoad"
              alt=""
              srcset=""
            />
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- <div class="con1">
        <img src="../../../assets/img/QingXi/jianjie.png" alt="">
          <div class="desc">
              汉源县清溪风电场项目位于清溪镇永安村，距离清溪镇政府5公里、县城38公里，由四川汉源铁能新能源开发有限公司投资开发建设。项目一期工程计划总投资4.6亿元，设计规模为19台单机，总装机容量47.5MW，设计年平均发电量9510.8万千瓦时。工程于2015年2月开工建设，于2016年9月完工，11月实现竣工验收；2017年1月25日启动试运行，2月8日正式进入商业运营。2017年，项目累计发电6349万千瓦时，实现主营业务收入2886万元,2017年9月份纳入规模企业统计，实现了当年投产当年入规入统。2018年， 累计发电6828万千瓦时，实现主营业务收入3315万元。2019年，累计发电7577万千瓦时，实现主营业务收入3663万元。2020年， 累计发电6890万千瓦时，实现主营业务收入3269.05万元。2021年， 清溪风电场共计发电量6210.59万千瓦时，实现发电收入2896.46万元(含国补)。目前，清溪风电场项目二期工程正在做前期准备工作。
          </div>
      </div> -->
      <!-- 事项公开标题 -->
      <div style="margin-top: 30px;text-align:center;">
          <img src="../../../assets/img/blue.png" alt="">
          <div class="bottom_br"></div>
        </div>
      <!-- 事项公开搜索 -->
      <div class="search-box">
        <el-input
          placeholder="搜索服务事项"
          suffix-icon="el-icon-search"
          v-model="keywords" @focus="toSearchView"
        >
        </el-input>
      </div>
      <GovernmentServices :mapId="mapId" :name="name" color="#0AABD8"></GovernmentServices>
    </div>
  </div>
</template>
<script>


import GovernmentServices from "@/components/GovernmentServices/index";
import { getServerDate, matterQuery } from "@/api/map/index"
import { mixin } from "../../../mixin";
export default {
  name: 'QingXi',
  components: { GovernmentServices },
  data() {
    return {
      matterList: [],  //事项列表
      villages: [],  //乡镇列表
      name:'清溪镇',
      keywords:'',
      summerImgList: [
      { imgUrl: require("../../../assets/img/QingXi/banner.jpg") },
      { imgUrl: require("../../../assets/img/QingXi/summer_banner.jpg") },
      ],
      winterImgList: [
      { imgUrl: require("../../../assets/img/QingXi/banner.jpg") },
      { imgUrl: require("../../../assets/img/QingXi/banner1.jpg") },
      ],
      autumnImgList: [
      { imgUrl: require("../../../assets/img/QingXi/banner.jpg") },
      { imgUrl: require("../../../assets/img/QingXi/autumn_banner.jpg") },
      ],
    };
  },
  mixins:[mixin],
  created() {
    this.$emit('public_header', false);
  },

};
</script>
<style scoped>
.banner {
  width: 100%;
  min-height: 215px;
  max-height: 427px;
}
.banner img{
  height: auto;
}
.con1 {
  position: relative;
  margin-top: -5px;
}
.con1 img{
  width: 100%;
}
.desc {
  font-size: 14px;
  color: #fff;
  position: absolute;
  top: 15%;
  left: 29%;
  overflow-y: auto;
  text-indent: 1em;
  width: 64%;
  height: 65%;
}
.search-box {
  margin: 0 20px;
  margin-top: 20px;
  margin-bottom: 10px;
}
/deep/.autumn .el-input__suffix{
  color: #384890;
  font-size: 20px;
}
/deep/.summer .el-input__suffix{
  color: #B71D3B;
  font-size: 20px;
}
/deep/.winter .el-input__suffix{
  color: #0AABD8;
  font-size: 20px;
}
/deep/.autumn .el-carousel__indicator.is-active button {
  background-color: #384890;
}
/deep/.summer .el-carousel__indicator.is-active button {
  background-color: #B71D3B;
}
/deep/.winter .el-carousel__indicator.is-active button {
  background-color: #0AABD8;
}
/deep/.el-carousel__button {
  background-color: gray;
}
</style>