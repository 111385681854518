<template>
  <div class="PianMa" v-loading="loading">
    <!-- 冬季：10-12月 -->
    <div class="winter" v-if="(nowMoth >= 1 && nowMoth <= 2) || nowMoth == 12">
      <div class="banner">
        <el-carousel :height="imgHeight">
          <el-carousel-item v-for="(item, index) in winterImgList" :key="'winter'+index+nowMoth">
            <img
              width="100%"
              v-lazy="item.imgUrl"
              ref="imgH"
              @load="imgLoad"
              alt=""
              srcset=""
            />
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- <div class="con1">
        <img src="../../../assets/img/PianMa/winter_jianjie.png" alt="" />
        <div class="desc">
          <p>
            当归具有补血活血，调经止痛，润肠通便之功效。常用于血虚萎黄，眩晕心悸，月经不调，经闭痛经，虛寒腹痛，风湿痹痛，跌扑损伤，痈疽疮疡，肠燥便秘。酒当归活血通经。用于经闭痛经，风湿痹痛，跌扑损伤。
          </p>
          <p>
            片马彝族乡种植有当归700余亩，主要品种为东当归，又叫大和归、日本当归、延边当归。预计今年我乡当归将实现亩产5000元，总产值可达350万元。
          </p>
          <p>
            片马彝族乡结合自身地理位置优势，大力发展特色中药材产业，其中牛膝种植面积将近500亩。牛膝性味苦、甘、酸、平，入肝、肾经，具有活血通经、补肝肾、利水通淋、引火(血)下行等功效。牛膝去杂质，用水冲洗净，润透后切段，放入锅中加入适量的水，熬制后即可食用。
          </p>
        </div>
      </div> -->
      <!-- 事项公开标题 -->
      <div style="margin-top: 30px; text-align: center">
        <img src="../../../assets/img/dark brown.png" alt="" />
        <div class="bottom_br"></div>
      </div>
      <!-- 事项公开搜索 -->
      <div class="search-box">
        <el-input
          placeholder="搜索服务事项"
          suffix-icon="el-icon-search"
          v-model="keywords" @focus="toSearchView"
        >
        </el-input>
      </div>
      <GovernmentServices
        :mapId="mapId"
        :name="name"
        color="#7F3D2A"
      ></GovernmentServices>
    </div>
    <!-- 四季 -->
    <div class="autumn" v-else>
      <div class="banner">
        <el-carousel :height="imgHeight">
          <el-carousel-item v-for="(item, index) in imgList" :key="'img'+index+nowMoth">
            <img
              width="100%"
              v-lazy="item.imgUrl"
              ref="imgH"
              @load="imgLoad"
              alt=""
              srcset=""
            />
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- <div class="con1">
        <img src="../../../assets/img/PianMa/jianjie.png" alt="" />
        <div class="desc">
          <p>
            近年来，汉源县片马彝族乡围绕建设“灵秀片马、核桃之乡”的目标，把核桃产业作为助力脱贫攻坚、助推乡村振兴的优势主导产业，依托得天独厚的地域优势、气候条件，深挖片马核桃千年种植历史，聚力建基地、搞加工、创品牌、促融合，全产业链发展核桃产业，打造“小核桃大产业”!
          </p>
          <p>
            近年来，片马彝族乡紧紧围绕“一核多元”的产业发展模式，在大力发展2.5万亩核桃产业的基础上，积极推进花椒、中药材、脆红李等特色农产品的种植。目前，片马彝族乡共种植花椒5000余亩，品种有青花椒、红花椒两大种类，青花椒属灌木，果皮可作为调味料，可提取芳香油，又可入药。种子可食用，又可加工制作肥皂。具有降低血压、增加食欲、驱虫等功效。红花椒是芸香料花椒的干燥成熟果皮，外表面深红色、紫红色或棕红色，皱缩，有众多点状凸起的油点，果皮革质，稍韧，有特异香气，味持久麻辣。具有杀菌止痛、杀虫止痒等功效。
          </p>
        </div>
      </div> -->
      <!-- 事项公开标题 -->
      <div style="margin-top: 30px; text-align: center">
        <img src="../../../assets/img/dark blue.png" alt="" />
        <div class="bottom_br"></div>
      </div>
      <!-- 事项公开搜索 -->
      <div class="search-box">
        <el-input
          placeholder="搜索服务事项"
          suffix-icon="el-icon-search"
          v-model="keywords" @focus="toSearchView"
        >
        </el-input>
      </div>
      <GovernmentServices
        :mapId="mapId"
        :name="name"
        color="#163D96"
      ></GovernmentServices>
    </div>
  </div>
</template>
<script>


import GovernmentServices from "@/components/GovernmentServices/index";
import { getServerDate, matterQuery } from "@/api/map/index";
import { mixin } from "../../../mixin";
export default {
  name: "PianMa",
  components: {  GovernmentServices },
  data() {
    return {
      matterList: [], //事项列表
      villages: [], //乡镇列表
      name: "片马彝族乡",
      keywords: "",
      winterImgList: [
        { imgUrl: require("../../../assets/img/PianMa/banner.jpg") },
        { imgUrl: require("../../../assets/img/PianMa/winter_banner1.jpg") },
        { imgUrl: require("../../../assets/img/PianMa/winter_banner2.jpg") },
      ],
      imgList: [
        { imgUrl: require("../../../assets/img/PianMa/banner.jpg") },
        { imgUrl: require("../../../assets/img/PianMa/banner1.jpg") },
        { imgUrl: require("../../../assets/img/PianMa/banner2.jpg") },
        { imgUrl: require("../../../assets/img/PianMa/banner3.jpg") },
      ],
    };
  },
  mixins: [mixin],
  created() {
    this.$emit("public_header", false);
  },
};
</script>
<style scoped>
.banner {
  width: 100%;
  min-height: 215px;
  max-height: 427px;
}
.con1 {
  position: relative;
  margin-top: -5px;
}
.con1 img {
  width: 100%;
}
.desc {
  font-size: 14px;
  color: #fff;
  position: absolute;
  top: 15%;
  left: 29%;
  overflow-y: auto;
  text-indent: 1em;
  width: 64%;
  height: 65%;
}
.search-box {
  margin: 0 20px;
  margin-top: 20px;
  margin-bottom: 10px;
}

/deep/.winter .el-input__suffix {
  color: #7f3d2a;
  font-size: 20px;
}
/deep/.autumn .el-input__suffix {
  color: #163d96;
  font-size: 20px;
}
/deep/.winter .el-carousel__indicator.is-active button {
  background-color: #7f3d2a;
}
/deep/.autumn .el-carousel__indicator.is-active button {
  background-color: #163d96;
}
/deep/.el-carousel__button {
  background-color: gray;
}
</style>