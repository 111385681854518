<template>
  <div class="QianYu" v-loading="loading">
    <!--春季：3-5月-->
    <div class="spring" v-if="nowMoth >= 3 && nowMoth <= 5">
      <div class="banner">
        <el-carousel :height="imgHeight">
          <el-carousel-item v-for="(item, index) in springImgList" :key="'spring'+index+nowMoth">
            <img
              width="100%"
              v-lazy="item.imgUrl"
              ref="imgH"
              @load="imgLoad"
              alt=""
              srcset=""
            />
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- <div class="con1">
        <img src="../../../assets/img/QianYu/spring_jianjie.png" alt="" />
        <div class="desc">
          <p>
            去汉源县前域镇——白鹤花卉种植专业合作社有客偏从陋世来，仙姿雅意费疑猜。粉红玉女通神韵，岂教风尘染两腮。
          </p>
          <p>
            走进前域镇力升家庭农场草莓育苗基地，一片绿意葱茏的草莓苗长势喜人，农场负责人高荣超正组织30余名工人忙着起苗、择苗、捆绑...这些精心挑选出来的草莓苗一部分将运回温室大棚内进行栽种，一部分将进行销售。
          </p>
        </div>
      </div> -->
      <!-- 事项公开标题 -->
      <div style="margin-top: 30px; text-align: center">
        <img src="../../../assets/img/xianhong.png" alt="" />
        <div class="bottom_br"></div>
      </div>
      <!-- 事项公开搜索 -->
      <div class="search-box">
        <el-input
          placeholder="搜索服务事项"
          suffix-icon="el-icon-search"
          v-model="keywords" @focus="toSearchView"
        >
        </el-input>
      </div>
      <GovernmentServices
        :mapId="mapId"
        :name="name"
        color="#EC3942"
      ></GovernmentServices>
    </div>
    <!-- 夏季：6-8月 -->
    <div class="summer" v-else-if="nowMoth >= 6 && nowMoth <= 8">
      <div class="banner">
        <el-carousel :height="imgHeight">
          <el-carousel-item v-for="(item, index) in summerImgList" :key="'summer'+index+nowMoth">
            <img
              width="100%"
              v-lazy="item.imgUrl"
              ref="imgH"
              @load="imgLoad"
              alt=""
              srcset=""
            />
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- <div class="con1">
        <img src="../../../assets/img/QianYu/summer_jianjie.png" alt="" />
        <div class="desc">
          据前域镇相关负责人介绍，果农们常年坚持使用农家肥、科学疏花疏果有效保证了水果的品质，因此果树结出的果实不仅颗粒大、水分足，口感也很好。
        </div>
      </div> -->
      <!-- 事项公开标题 -->
      <div style="margin-top: 30px; text-align: center">
        <img src="../../../assets/img/red.png" alt="" />
        <div class="bottom_br"></div>
      </div>
      <!-- 事项公开搜索 -->
      <div class="search-box">
        <el-input
          placeholder="搜索服务事项"
          suffix-icon="el-icon-search"
          v-model="keywords" @focus="toSearchView"
        >
        </el-input>
      </div>
      <GovernmentServices
        :mapId="mapId"
        :name="name"
        color="#BD0000"
      ></GovernmentServices>
    </div>
    <!-- 秋季：9-11月 -->
    <div class="autumn" v-else-if="nowMoth >= 9 && nowMoth <= 11">
      <div class="banner">
        <el-carousel :height="imgHeight">
          <el-carousel-item v-for="(item, index) in autumnImgList" :key="'autumn'+index+nowMoth">
            <img
              width="100%"
              v-lazy="item.imgUrl"
              ref="imgH"
              @load="imgLoad"
              alt=""
              srcset=""
            />
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- <div class="con1">
        <img src="../../../assets/img/QianYu/autumn_jianjie.png" alt="" />
        <div class="desc">
          据前域镇相关负责人介绍，前域镇的农作物常年坚持使用农家肥、科学疏花疏果，有效保证了品质，因此果树结出的果实不仅颗粒大、水分足，口感也很好，农作物收成也好。
        </div>
      </div> -->
      <!-- 事项公开标题 -->
      <div style="margin-top: 30px; text-align: center">
        <img src="../../../assets/img/yellow.png" alt="" />
        <div class="bottom_br"></div>
      </div>
      <!-- 事项公开搜索 -->
      <div class="search-box">
        <el-input
          placeholder="搜索服务事项"
          suffix-icon="el-icon-search"
          v-model="keywords" @focus="toSearchView"
        >
        </el-input>
      </div>
      <GovernmentServices
        :mapId="mapId"
        :name="name"
        color="#A16D09"
      ></GovernmentServices>
    </div>
    <!-- 冬季：1、2、12月 -->
    <div class="winter" v-else>
      <div class="banner">
        <el-carousel :height="imgHeight">
          <el-carousel-item v-for="(item, index) in winterImgList" :key="'winter'+index+nowMoth">
            <img
              width="100%"
              v-lazy="item.imgUrl"
              ref="imgH"
              @load="imgLoad"
              alt=""
              srcset=""
            />
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- <div class="con1">
        <img src="../../../assets/img/QianYu/winter_jianjie.png" alt="" />
        <div class="desc">
          据前域镇相关负责人介绍，前域镇的农作物常年坚持使用农家肥、科学疏花疏果，有效保证了品质，因此果树结出的果实不仅颗粒大、水分足，口感也很好，农作物收成也好。
        </div>
      </div> -->
      <!-- 事项公开标题 -->
      <div style="margin-top: 30px; text-align: center">
        <img src="../../../assets/img/olivine.png" alt="" />
        <div class="bottom_br"></div>
      </div>
      <!-- 事项公开搜索 -->
      <div class="search-box">
        <el-input
          placeholder="搜索服务事项"
          suffix-icon="el-icon-search"
          v-model="keywords" @focus="toSearchView"
        >
        </el-input>
      </div>
      <GovernmentServices
        :mapId="mapId"
        :name="name"
        color="#697614"
      ></GovernmentServices>
    </div>
  </div>
</template>
<script>


import GovernmentServices from "@/components/GovernmentServices/index";
import { getServerDate, matterQuery } from "@/api/map/index";
import { mixin } from "../../../mixin";
export default {
  name: "QianYu",
  components: {  GovernmentServices },
  data() {
    return {
      matterList: [], //事项列表
      villages: [], //乡镇列表
      name: "前域镇",
      keywords: "",
      springImgList: [
        { imgUrl: require("../../../assets/img/QianYu/banner.jpg") },
        { imgUrl: require("../../../assets/img/QianYu/spring_banner1.jpg") },
        { imgUrl: require("../../../assets/img/QianYu/spring_banner2.jpg") }
      ],
      summerImgList: [
        { imgUrl: require("../../../assets/img/QianYu/banner.jpg") },
        { imgUrl: require("../../../assets/img/QianYu/summer_banner1.jpg") },
        { imgUrl: require("../../../assets/img/QianYu/summer_banner2.jpg") },
        { imgUrl: require("../../../assets/img/QianYu/summer_banner3.jpg") },
      ],
      autumnImgList: [
        { imgUrl: require("../../../assets/img/QianYu/banner.jpg") },
        { imgUrl: require("../../../assets/img/QianYu/autumn_banner1.jpg") },
        { imgUrl: require("../../../assets/img/QianYu/autumn_banner2.jpg") },
        { imgUrl: require("../../../assets/img/QianYu/autumn_banner3.jpg") },
      ],
      winterImgList: [
        { imgUrl: require("../../../assets/img/QianYu/banner.jpg") },
        { imgUrl: require("../../../assets/img/QianYu/winter_banner1.jpg") },
        { imgUrl: require("../../../assets/img/QianYu/winter_banner2.jpg") },
      ],
    };
  },
  mixins: [mixin],
  created() {
    this.$emit("public_header", false);
    // getServerDate( {
    //     method: "/zwmap/getDate",
    //     body: '',
    //   }).then(res => {
    //     // console.log(res,'时间')
    //     if (res.data.code == 200) {
    //        let now = new Date(res.data.code),
    //         m = now.getMonth() + 1;
    //        this.nowMoth = m;
    //        console.log('month',this.nowMoth)
    //     }
    //   })
  },
};
</script>
<style scoped>
.banner {
  width: 100%;
  min-height: 215px;
  max-height: 427px;
}
.con1 {
  position: relative;
  margin-top: -5px;
}
.con1 img {
  width: 100%;
}
.desc {
  font-size: 14px;
  color: #fff;
  position: absolute;
  top: 15%;
  left: 29%;
  overflow-y: auto;
  text-indent: 1em;
  width: 64%;
  height: 65%;
}
.search-box {
  margin: 0 20px;
  margin-top: 20px;
  margin-bottom: 10px;
}
/deep/.spring .el-input__suffix {
  color: #ec3942;
  font-size: 20px;
}
/deep/.summer .el-input__suffix {
  color: #bd0000;
  font-size: 20px;
}
/deep/.autumn .el-input__suffix {
  color: #d9ab00;
  font-size: 20px;
}
/deep/.winter .el-input__suffix {
  color: #569400;
  font-size: 20px;
}
/deep/.spring .el-carousel__indicator.is-active button {
  background-color: #ec3942;
}
/deep/.summer .el-carousel__indicator.is-active button {
  background-color: #bd0000;
}
/deep/.autumn .el-carousel__indicator.is-active button {
  background-color: #d9ab00;
}
/deep/.winter .el-carousel__indicator.is-active button {
  background-color: #569400;
}
/deep/.el-carousel__button {
  background-color: gray;
}
</style>