<template>
  <div class="AnLe" v-loading="loading">
    <!--春季&冬季  春季：3-5月 冬季：12-2-->
    <div>
      <div
        class="springAndwinter"
        v-if="(nowMoth >= 1 && nowMoth <= 5) || nowMoth == 12"
      >
        <div class="banner">
          <el-carousel :height="imgHeight">
          <el-carousel-item v-for="(item,index) in springImgList" :key='"spring"+index+nowMoth'>
            <img
              width="100%"
              v-lazy="item.imgUrl" ref="imgH" @load="imgLoad"
            />
          </el-carousel-item>
        </el-carousel>
        </div>
        <!-- <div class="con1">
          <img
            src="../../../assets/img/AnLe/springAndWinter_jianjie_bj.png"
            alt=""
          />
          <div class="desc">
            <p>
              黄果柑，又名青果、黄金果。果实圆球形，果形端正，果蒂、果顶平，果皮黄色、鲜艳、有光泽。汉源县安乐镇正是黄果柑的生长和发源地之一，阳光城给黄果柑提供了充足的光照，正是这充足的光照、适宜的海拔和巨大的昼夜温差使得汉源的黄果柑具有汁水丰盈、入口爆浆、肉质细腻、清新爽口、果味浓郁的独特口感。安乐镇光照充足，气候冷凉干燥，昼夜温差大，正是这得天独厚的自然条件，使得安乐黄果柑品质极佳。如今漫山遍野金灿灿的黄果柑不仅是安乐镇广大群众的致富果，更是安乐镇产业发展的一道风景线，写满了安乐镇群众致富的幸福笑容，更扮靓了安乐镇群众奔康的富强梦想。
            </p>
            <p>
              安乐镇黄果柑品种不断更新优化，种植技术不断提升，现在安乐镇黄果柑有着品相好、水分足、酸甜适宜等特点。小小的黄果柑，成为当地果农经济收入的主要来源。
            </p>
          </div>
        </div> -->
        <!-- 事项公开标题 -->
        <div style="margin-top: 30px; text-align: center">
          <img src="../../../assets/img/orange.png" alt="" />
          <div class="bottom_br"></div>
        </div>
        <!-- 事项公开搜索 -->
        <div class="search-box">
          <el-input
            placeholder="搜索服务事项"
            suffix-icon="el-icon-search"
            v-model="keywords" @focus="toSearchView"
          >
          </el-input>
        </div>
        <GovernmentServices
          :mapId="mapId"
          :name="name"
          color="#DC6A06"
        ></GovernmentServices>
      </div>
      <div class="feature" v-else>
        <div class="banner">
          <el-carousel :height="imgHeight">
            <el-carousel-item v-for="(item,index) in featureImgList" :key='"feature"+index+nowMoth'>
              <img
                width="100%"
                v-lazy="item.imgUrl" ref="imgH" @load="imgLoad"
              />
            </el-carousel-item>
        </el-carousel>
        </div>
        <!-- <div class="con1">
          <img src="../../../assets/img/AnLe/jianjie_bj.png" alt="" />
          <div class="desc">
            “杆杆酒”在彝语中叫“芝衣”，又称“泡水酒”
            “咂酒”，是彝族人民喜庆节日时用来招待客人的一种别具风味的水酒。这种酒亦采用玉米、高粱和荞子酿制而成，沙坪彝族村的杆杆酒用坛子盛装，饮用时，用细竹秤插入坛中吸饮或用它接入酒杯饮用。杆杆酒以其吸饮工具而得名，“杆杆酒”营养丰富、口感纯厚。
          </div>
        </div> -->
        <!-- 事项公开标题 -->
        <div style="margin-top: 30px; text-align: center">
          <img src="../../../assets/img/red.png" alt="" />
          <div class="bottom_br"></div>
        </div>
        <!-- 事项公开搜索 -->
        <div class="search-box">
          <el-input
            placeholder="搜索服务事项"
            suffix-icon="el-icon-search"
            v-model="keywords" @focus="toSearchView"
          >
          </el-input>
        </div>
        <GovernmentServices
          :mapId="mapId"
          :name="name"
          color="#920C05"
        ></GovernmentServices>
      </div>
    </div>
  </div>
</template>
<script>


import GovernmentServices from "@/components/GovernmentServices/index";
import { getServerDate, matterQuery } from "@/api/map/index";
import { mixin } from "../../../mixin";
export default {
  name: "AnLe",
  components: {  GovernmentServices },
  data() {
    return {
      matterList: [], //事项列表
      villages: [], //乡镇列表
      name: "安乐镇",
      keywords: "",
      springImgList: [
        {imgUrl:require('../../../assets/img/AnLe/banner.jpg')},
        {imgUrl:require('../../../assets/img/AnLe/Anle_Banner_springAndwinter.jpg')},
      ],
      featureImgList: [
        {imgUrl:require('../../../assets/img/AnLe/banner.jpg')},
        {imgUrl:require('../../../assets/img/AnLe/AnLeBanner.jpg')},
      ],
    };
  },
  mixins: [mixin],
  created() {
    this.$emit("public_header", false);
  },
  mounted() {},
};
</script>
<style scoped>
.banner {
  width: 100%;
}
.banner img {
  position: unset;
  margin: 0;
}
.con1 {
  position: relative;
  margin-top: -5px;
}
.con1 img {
  width: 100%;
}
.desc {
  font-size: 14px;
  color: #fff;
  position: absolute;
  top: 15%;
  left: 29%;
  overflow-y: auto;
  text-indent: 1em;
  width: 64%;
  height: 65%;
}
.search-box {
  margin: 0 20px;
  margin-top: 20px;
  margin-bottom: 10px;
}
/deep/.springAndwinter .el-input__suffix {
  color: #e79e5f;
  font-size: 20px;
}
/deep/.feature .el-input__suffix {
  color: #dc6860;
  font-size: 20px;
}
/deep/.springAndwinter .el-carousel__indicator.is-active button {
  background-color: #e79e5f;
}
/deep/.feature .el-carousel__indicator.is-active button {
  background-color: #dc6860;
}
/deep/.el-carousel__button {
  background-color: gray;
}
</style>