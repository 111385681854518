<template>
  <div class="MaLie" v-loading="loading">
    <!-- 秋季：9-11月 -->
    <div class="autumn" v-if="nowMoth >= 9 && nowMoth <= 11">
      <div class="banner">
        <el-carousel :height="imgHeight">
          <el-carousel-item v-for="(item,index) in autumnImgList" :key='"autumn"+index+nowMoth'>
            <img
              width="100%"
              v-lazy="item.imgUrl"
              ref="imgH"
              @load="imgLoad"
              alt=""
              srcset=""
            />
          </el-carousel-item>
        </el-carousel>
        <!-- <img
          width="100%"
          src="../../../assets/img/MaLie/autumn_banner.jpg"
          alt=""
          srcset=""
        /> -->
      </div>
      <div class="con1">
        <img src="../../../assets/img/MaLie/autumn_jianjie.png" alt="" />
        <div class="desc">
          马烈高山核桃，富含多种身体所需维生素，皮薄肉多，清香可口，具有补脑、开胃等作用。
        </div>
      </div>
      <!-- 事项公开标题 -->
      <div style="margin-top: 30px; text-align: center">
        <img src="../../../assets/img/dark brown.png" alt="" />
        <div class="bottom_br"></div>
      </div>
      <!-- 事项公开搜索 -->
      <div class="search-box">
        <el-input
          placeholder="搜索服务事项"
          suffix-icon="el-icon-search"
          v-model="keywords" @focus="toSearchView"
        >
        </el-input>
      </div>
      <GovernmentServices
        :mapId="mapId"
        :name="name"
        color="#82412E"
      ></GovernmentServices>
    </div>
    <!-- 冬季：12-2月 -->
    <div
      class="winter"
      v-else-if="nowMoth == 12 || (nowMoth >= 1 && nowMoth <= 2)"
    >
      <div class="banner">
        <!-- <img
          width="100%"
          src="../../../assets/img/MaLie/winter_banner.jpg"
          alt=""
          srcset=""
        /> -->
        <el-carousel :height="imgHeight">
          <el-carousel-item v-for="(item,index) in winterImgList" :key='"winter"+index+nowMoth'>
            <img
              width="100%"
              v-lazy="item.imgUrl"
              ref="imgH"
              @load="imgLoad"
              alt=""
              srcset=""
            />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="con1">
        <img src="../../../assets/img/MaLie/winter_jianjie.png" alt="" />
        <div class="desc">
          马烈萝卜生长于海拔 2000米以上山地，昼夜温差大，味道甘甜，清脆爽口。
        </div>
      </div>
      <!-- 事项公开标题 -->
      <div style="margin-top: 30px; text-align: center">
        <img src="../../../assets/img/olivine.png" alt="" />
        <div class="bottom_br"></div>
      </div>
      <!-- 事项公开搜索 -->
      <div class="search-box">
        <el-input
          placeholder="搜索服务事项"
          suffix-icon="el-icon-search"
          v-model="keywords" @focus="toSearchView"
        >
        </el-input>
      </div>
      <GovernmentServices
        :mapId="mapId"
        :name="name"
        color="#4E6D04"
      ></GovernmentServices>
    </div>
    <!-- 四季 -->
    <div class="spring" v-else>
      <div class="banner">
        <el-carousel :height="imgHeight">
          <el-carousel-item v-for="(item,index) in imgList" :key='"img"+index+nowMoth'>
            <img
              width="100%"
              v-lazy="item.imgUrl"
              ref="imgH"
              @load="imgLoad"
              alt=""
              srcset=""
            />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="con1">
        <img src="../../../assets/img/MaLie/jianjie.png" alt="" />
        <div class="desc">
          <p>
            马烈高山牦牛，朝饮甘露夜食草，成就一身优质肉，鲜嫩多汁味香浓。马烈跑山猪，全天侯放养锻炼，肉质紧实、瘦而不柴。
          </p>
          <p>马烈高山冷水鱼，品种众多品质好，肉质细嫩味甘美。</p>
          <p>
            马烈腊肉，选用优质土猪，采用香樟树枝熏制而成，口感鲜香鲜美、入口即化。
          </p>
        </div>
      </div>
      <!-- 事项公开标题 -->
      <div style="margin-top: 30px; text-align: center">
        <img src="../../../assets/img/dark blue.png" alt="" />
        <div class="bottom_br"></div>
      </div>
      <!-- 事项公开搜索 -->
      <div class="search-box">
        <el-input
          placeholder="搜索服务事项"
          suffix-icon="el-icon-search"
          v-model="keywords" @focus="toSearchView"
        >
        </el-input>
      </div>
      <GovernmentServices
        :mapId="mapId"
        :name="name"
        color="#143A96"
      ></GovernmentServices>
    </div>
  </div>
</template>
<script>


import GovernmentServices from "@/components/GovernmentServices/index";
import { getServerDate, matterQuery } from "@/api/map/index";
import { mixin } from "../../../mixin";
export default {
  name: "MaLie",
  components: {  GovernmentServices },
  data() {
    return {
      matterList: [], //事项列表
      villages: [], //乡镇列表
      name: "马烈乡",
      keywords: "",
      imgList: [
        { imgUrl: require("../../../assets/img/MaLie/banner.jpg") },
        { imgUrl: require("../../../assets/img/MaLie/banner2.jpg") },
        { imgUrl: require("../../../assets/img/MaLie/banner3.jpg") },
      ],
      autumnImgList: [
        { imgUrl: require("../../../assets/img/MaLie/banner.jpg") },
        { imgUrl: require("../../../assets/img/MaLie/autumn_banner.jpg") },
      ],
      winterImgList: [
        { imgUrl: require("../../../assets/img/MaLie/banner.jpg") },
        { imgUrl: require("../../../assets/img/MaLie/winter_banner.jpg") },
      ],
    };
  },
  mixins: [mixin],
  created() {
    this.$emit("public_header", false);
  },
};
</script>
<style scoped>
.banner {
  width: 100%;
  min-height: 215px;
  max-height: 427px;
}
.con1 {
  position: relative;
  margin-top: -5px;
}
.con1 img {
  width: 100%;
}
.desc {
  font-size: 14px;
  color: #fff;
  position: absolute;
  top: 15%;
  left: 29%;
  overflow-y: auto;
  text-indent: 1em;
  width: 64%;
  height: 65%;
}
.search-box {
  margin: 0 20px;
  margin-top: 20px;
  margin-bottom: 10px;
}
/deep/.spring .el-input__suffix {
  color: #143a96;
  font-size: 20px;
}
/deep/.winter .el-input__suffix {
  color: #4e6d04;
  font-size: 20px;
}
/deep/.autumn .el-input__suffix {
  color: #82412e;
  font-size: 20px;
}
/deep/.spring .el-carousel__indicator.is-active button {
  background-color: #143a96;
}
/deep/.winter .el-carousel__indicator.is-active button {
  background-color: #4e6d04;
}
/deep/.autumn .el-carousel__indicator.is-active button {
  background-color: #82412e;
}
/deep/.el-carousel__button {
  background-color: gray;
}
</style>